<template>
	<div class="row">
      <div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
            <i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body pb-0">
               <div class="row">
                  <div class="col-md mb-3">
							<label><i class="far fa-user color-theme font-11 me-1"></i> Nome/CPF/CNPJ/Email</label>
                     <input type="text" class="form-control" v-model="pesquisa.valor" maxlength="200" v-focus @keyup.enter="buscarUsuarios" placeholder="Busque por nome da conta, CPF, CNPJ ou email" />
                  </div>
						<div class="col-md-4 col-xl-2 px-0 mb-3 align-self-end">
							<div class="row">
                        <div class="col">
									<button type="button" class="btn btn-primary w-100" @click="buscarUsuarios"><i class="fas fa-search font-13"></i></button>
                        </div>
                        <div class="col-8">
									<button type="button" class="btn btn-primary w-100" @click="abrirModal(null)">
										<i class="far fa-plus font-13 me-1"></i> Adicionar
									</button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-12" v-if="pesquisa.retorno.resultado.length > 0">
			<div class="card mb-0">
				<div class="card-body p-12">
					<div class="row">
						<div class="col mb-2 align-self-center">
							<div class="limitador-1 mb-1"><i class="fas fa-angle-right color-theme me-1"></i> {{ pesquisa.retorno.resultado.length == 100 ? 'Limitado a ': '' }} {{ pesquisa.retorno.resultado.length }} resultado(s)</div>
						</div>
						<!-- <div class="w-max-content mb-2">
							<a href="javascript:;" class="badge badge-default bg-theme mb-1"><i class="far fa-share font-10 me-1"></i> Exportar</a>
						</div> -->

						<usuario v-for="(usuario, index) in pesquisa.retorno.resultado" :key="index" :usuario="usuario" :index="index" :tela="'USUARIOS'"
							@editar="abrirModal($event)" />
					</div>
				</div>
			</div>
      </div>

      <!-- modalUsuario -->
      <div class="modal fade" id="modalUsuario" tabindex="-1" aria-labelledby="modalUsuarioLabel" aria-hidden="true">
         <div class="modal-dialog modal-xxl h-100 modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalUsuarioLabel">{{ modal.id == null ? 'Adicionar' : 'Editar' }} usuário {{ modal.id == null ? '' : ('('+ modal.nomeCompleto +')') }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="card mb-2">
							<div class="card-body p-12">
								<ul class="nav nav-pills" id="pills-tab" role="tablist">
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0 active" id="pills-usuario-tab" data-bs-toggle="pill" data-bs-target="#pills-usuario" type="button" role="tab" aria-controls="pills-usuario" aria-selected="true">
											<i class="far fa-home font-12"></i><span class="ms-1 d-none d-sm-inline"> Principal</span>
										</button>
									</li>
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0" id="pills-permissoes-tab" data-bs-toggle="pill" data-bs-target="#pills-permissoes" type="button" role="tab" aria-controls="pills-permissoes" aria-selected="false">
											<i class="far fa-lock font-12"></i><span class="ms-1 d-none d-sm-inline"> Permissões</span>
										</button>
									</li>
								</ul>
							</div>
						</div>

						<div class="tab-content" id="pills-tabContent">
							<div class="tab-pane fade show active" id="pills-usuario" role="tabpanel" aria-labelledby="pills-usuario-tab">
								<div class="accordion" id="accordionExample">
									<div class="accordion-item">
										<h2 class="accordion-header" id="headingOne">
											<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
												<i class="fal fa-home"></i> Informações básicas
											</button>
										</h2>
										<div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
											<div class="accordion-body">
												<div class="row">
													<div class="col-sm-6 mb-3">
														<label><i class="far fa-user color-theme font-12 me-1"></i> Nome *</label>
														<input type="text" class="form-control" v-model="modal.nomeCompleto" maxlength="200" />
													</div>
													<div class="col-sm-6 mb-3">
														<label><i class="far fa-envelope color-theme font-11 me-1"></i> Email *</label>
														<input type="text" class="form-control" v-model="modal.email" maxlength="200" />
													</div>
													<div class="col-sm-6 mb-3">
														<label><i class="far fa-address-card color-theme font-11 me-1"></i> CPF/CNPJ*</label>
														<input type="text" class="form-control" v-model="modal.cpfCnpj" v-mask="['###.###.###-##', '##.###.###/####-##']" />
													</div>
													<div class="col-sm-6">
														<label class="mb-1"><i class="far fa-lock color-theme me-1 font-11"></i>Senha</label>
														<input type="password" class="form-control font-14" v-model="modal.senha" maxlength="200">
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="accordion-item">
										<h2 class="accordion-header" id="headingTwo">
											<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
												<i class="fal fa-map-marker-alt me-3"></i>Endereço
											</button>
										</h2>
										<div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
											<div class="accordion-body">
												<div class="row">
													<div class="col-sm-4 mb-3">
														<label><i class="far fa-location-arrow color-theme font-11 me-1"></i> CEP</label>
														<div class="input-group">
															<input type="text" class="form-control" v-model="modal.endereco.cep" v-mask="'##########'" @keyup.enter="buscarCEP" />
															<a href="javascript:;" class="input-group-text font-13" @click="buscarCEP">
																<i class="fas fa-search"></i>
															</a>
														</div>
													</div>
													<div class="col-sm-8 mb-3">
														<label><i class="far fa-home color-theme font-11 me-1"></i> Rua</label>
														<input type="text" class="form-control" v-model="modal.endereco.rua" maxlength="200" />
													</div>
													<div class="col-sm-4 mb-3">
														<label><i class="far fa-hashtag color-theme font-11 me-1"></i> Número</label>
														<input type="text" class="form-control" v-model="modal.endereco.numero" maxlength="200" />
													</div>
													<div class="col-sm-8 mb-3">
														<label><i class="far fa-road color-theme font-11 me-1"></i> Bairro</label>
														<input type="text" class="form-control" v-model="modal.endereco.bairro" maxlength="200" />
													</div>
													<div class="col-sm-6 mb-3">
														<label><i class="far fa-map-signs color-theme font-11 me-1"></i> Cidade</label>
														<input type="text" class="form-control" v-model="modal.endereco.cidade" maxlength="200" />
													</div>
													<div class="col-sm-6 mb-3">
														<label><i class="far fa-map-marker-alt color-theme font-11 me-1"></i> Estado</label>
														<input type="text" class="form-control" v-model="modal.endereco.estado" maxlength="200" />
													</div>
													<div class="col-sm-4 mb-3 mb-sm-0">
														<label><i class="far fa-map-signs color-theme font-11 me-1"></i> Cód. Município</label>
														<input type="text" class="form-control" v-model="modal.endereco.codMunicipio" maxlength="200" />
													</div>
													<div class="col-sm-8">
														<label><i class="far fa-tag color-theme font-11 me-1"></i> Complemento</label>
														<input type="text" class="form-control" v-model="modal.endereco.complemento" maxlength="200" />
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="accordion-item">
										<h2 class="accordion-header" id="headingThree">
											<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
												<i class="fal fa-phone-alt"></i> Telefone
											</button>
										</h2>
										<div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
											<div class="accordion-body">
												<div class="row">
													<div class="col-sm-3 mb-3">
														<label><i class="far fa-hashtag color-theme font-11 me-1"></i> DDD</label>
														<input type="text" class="form-control" v-model="modal.telefone.ddd" v-mask="['(##)', '(###)']" />
													</div>
													<div class="col-sm-5 mb-3">
														<label><i class="far fa-hashtag color-theme font-11 me-1"></i> Número</label>
														<input type="text" class="form-control" v-model="modal.telefone.telefone" v-mask="['####-####', '#####-####']" />
													</div>
													<div class="col-sm-4 mb-3 mb-sm-0">
														<label><i class="far fa-tag color-theme font-11 me-1"></i> Tipo</label>
														<v-select name="Desktop" :options="['Fixo', 'Celular', 'Whatsapp']" v-model="modal.telefone.tipo" :clearable="false" :searchable="false" placeholder="Selecione uma opção" />
														<select name="Mobile" class="form-control" v-model="modal.telefone.tipo">
															<option v-for="(option, index) in ['Fixo', 'Celular', 'Whatsapp']" :key="index" :value="option">{{ option }}</option>
														</select>
													</div>
													<div class="col-12">
														<label><i class="far fa-user-alt color-theme font-11 me-1"></i> Nome contato</label>
														<input type="text" class="form-control" v-model="modal.telefone.nomeContato" maxlength="200" />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="tab-pane fade" id="pills-permissoes" role="tabpanel" aria-labelledby="pills-permissoes-tab">
								<div class="row">
									<div class="col-lg-6 ps-lg-0">
										<div class="card mb-2">
											<div class="card-body p-12">
												<div class="row">
													<div class="col-12 mb-3">
														<h6 class="mb-0 mt-1 font-15"><i class="far fa-browser color-theme font-13 me-2"></i>Módulos</h6>
													</div>
													<modulo v-for="(modulo, index) in modal.modulos" :key="index" :index="index" :modulo="modulo" @toggle="toggleModulo($event)" />
													<div class="col-12 my-5 text-center" v-if="modal.modulos.length == 0">
														<i class="fal fa-align-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
														<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum módulo encontrado</h4>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-lg-6 pe-lg-0">
										<div class="card mb-2">
											<div class="card-body p-12">
												<div class="row">
													<div class="col-12 mb-3">
														<h6 class="mb-0 mt-1 font-15"><i class="far fa-store color-theme font-13 me-2"></i>Closers</h6>
													</div>
													<checkCloser v-for="(closer, index) in modal.closers" :key="index" :index="index" :closer="closer" />
													<div class="col-12 my-5 text-center" v-if="modal.closers.length == 0">
														<i class="fal fa-store-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
														<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum closer encontrado</h4>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
               </div>
               <div class="modal-footer">
						<button type="button" class="btn btn-primary" @click="salvarContas">Salvar</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import router from '@/router'
import { mapState } from 'vuex'
import checkCloser from '@/components/usuarios/CheckCloser.vue'
import modulo from '@/components/usuarios/CheckModulo.vue'
import usuario from '@/components/usuarios/Usuario.vue'

export default {
	name: 'Contas',
	data: function () {
		return {
			pesquisa: {'valor': '', 'retorno': {'nome': null, 'resultado': []}},
			modal: {
				'id': null, 'cpfCnpj': null, 'email': null, 'nomeCompleto': null, 'senha': null, 'valor': null, 'closers': [], 'modulos': [],
				'endereco': {'id': null, 'bairro': '', 'cep': '', 'cidade': '', 'codMunicipio': '', 'complemento': '', 'estado': '', 'numero': '', 'pais': '', 'rua': ''},
				'telefone': {'id': null, 'ddd': '', 'nomeContato': '', 'telefone': '', 'tipo': 'Fixo'},
			},
			closersList: []
		}
	},
	computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         urlRest: state => state.urlRest,
			tabs: state => state.tabs
      })
   },
	watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function (value) {
         Object.assign(this.$data, this.tabs[value].data)
      }
	},
	components: {
		checkCloser, usuario, modulo
	},
	methods: {
		abrirModal : function (usuario) {
			this.$store.dispatch('alternarTelaCarregamento', true)
			this.modal = {
				'id': null, 'cpfCnpj': null, 'rg': null, 'convenioHabilitado': false, 'dataNascimento': null, 'email': null, 'nomeCompleto': null, 'senha': null, 'valor': null,
				'endereco': {'id': null, 'bairro': '', 'cep': '', 'cidade': '', 'codMunicipio': '', 'complemento': '', 'estado': '', 'numero': '', 'pais': '', 'rua': ''},
				'telefone': {'id': null, 'ddd': '', 'nomeContato': '', 'telefone': '', 'tipo': 'Fixo'}, 'closers': [], 'modulos': []
			}

			this.$axios({
				method: 'get',
				url: this.urlRest +'configuracoesAdmin/getGerencialUsuarios',
				params: {
					email: usuario == null ? null : usuario.email
				}
			}).then(response => {
				$('#modalUsuario').modal('show')

				this.modal = {
					'id': response.data.id, 
					'dataCriacao': response.data.dataCriacao == null ? null : String(response.data.dataCriacao.dayOfMonth).padStart(2, '0') +'/'+ String(response.data.dataCriacao.monthValue).padStart(2, '0') +'/'+ response.data.dataCriacao.year, 
					'email': response.data.email,
					'cpfCnpj': response.data.cpfCnpj,
					'nomeCompleto': response.data.nomeCompleto,
					'closers': response.data.closers,
					'modulos': response.data.gerencialModulos,
					'senha': response.data.senha,
					'endereco': response.data.endereco,
					'telefone': response.data.telefone
				}
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				} else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		irParaPacotes : function () {
			router.push('/pacotes/'+ this.tabs.length)
		},
		buscarUsuarios : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)
			this.pesquisa.retorno = {'loja': null, 'nome': null, 'resultado': []}

			this.$axios({
				method: 'get',
				url: this.urlRest +'configuracoesAdmin/searchGerencialUsuarios',
				params: {
					valor: this.pesquisa.valor,
				}

			}).then(response => {
				this.pesquisa.retorno = response.data

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				} else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		toggleModulo : function (idModulo) {
         this.modal.modulos.forEach(modulo => {
            if (modulo.id == idModulo) {
               modulo.habilitado = !modulo.habilitado

               modulo.gerencialRoles.forEach(role => {
                  role.habilitado = modulo.habilitado
               });
            }
         });
      },
		buscarCEP : function () {
         if (String(this.modal.endereco.cep).trim().length < 8) {
            return;
         }

         this.$store.dispatch('buscarCEP', this.modal.endereco.cep).then((data) => {
				this.modal.endereco.rua = data.logradouro
				this.modal.endereco.complemento = data.complemento
				this.modal.endereco.bairro = data.bairro
				this.modal.endereco.cidade = data.localidade
				this.modal.endereco.estado = data.uf
				this.modal.endereco.codMunicipio = data.ibge
			})
      },
		salvarContas : function () {
			if (this.modal.cpfCnpj == null || String(this.modal.cpfCnpj).trim().length == 0 ||
				this.modal.nomeCompleto == null || String(this.modal.nomeCompleto).trim().length == 0 ||
				this.modal.email == null || String(this.modal.email).trim().length == 0) {

				this.$toast.fire({
					icon: 'error',
					title: 'Preencha todos os campos obrigatórios'
				});

				return
			}

			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'post',
				url: this.urlRest +'configuracoesAdmin/saveUsuarioGerencial',
				headers: {'Content-Type': 'application/json'},
				data: this.modal

			}).then(() => {
				$('#modalUsuario').modal('hide')
				this.buscarUsuarios()

				this.$toast.fire({
					icon: 'success',
					title: 'Usuário salvo!'
				});
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else if (error.response.status == 400) {
						this.$toast.fire({
							icon: 'error',
							title: 'Limite de usuários excedido!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				} else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
	},
	mounted() {
		Object.assign(this.$data, this.tabs[this.$route.params.idTab].data)
	}
}

</script>